import type { TagProps } from "~/components/tag";
import { withNamespace } from "./translation";
import type { JobPost } from "~/types/jben/job_post";

export const buildJobPostTags = (
  jobPost: { published_at: string; is_featured?: boolean },
  isPost?: boolean
) => {
  const t = withNamespace("common", { prefix: "tags" });

  const tags: Array<TagProps> = [];

  if (isNew(jobPost)) {
    tags.push({
      label: t("new"),
      tagStyle: "secondary",
    });
  }

  if (jobPost.is_featured && isPost) {
    tags.push({
      label: t("featured"),
      tagStyle: "featured",
    });
  }

  return tags;
};

const isNew = (jobPost: { published_at: string }) => {
  const sevenDaysAgo = new Date().getTime() - 7 * 24 * 60 * 60 * 1000;
  return new Date(jobPost.published_at).getTime() > sevenDaysAgo;
};

export const showEmployment = (jobPost: JobPost) => {
  if (!jobPost?.employment) {
    return false;
  }

  return jobPost.employment !== "hidden";
};

export const employmentRequired = (jobPost: JobPost) => jobPost.employment === "required";
